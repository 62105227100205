<template>
  <div class="breadcrumb">
    <ul>
      <li>
        <a href="/"><fa icon="home" class="icon" />Home</a>
      </li>
      <li>/</li>
      <li>{{menu}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    menu: String,
    path: String,
  },
  data() {
    return {
    }
  },
  methods: {
    showMenu() {
      this.menuDisplay = !this.menuDisplay
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  background: white;
  ul {
    margin: 5px 0px;
    margin-bottom: 0px;
    padding-inline-start: 20px;
    list-style: none;
    display: flex;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    .icon {
      height: 14px;
      margin: 0 3px;
      margin-right: 5px;
      padding-bottom: 3px;
      color: #5c63ae;
    }
    li {
      padding: 10px 5px;
      font-size: 14px;
    }
  }
}

@media (min-width: 751px) {

}

@media screen and (max-width: 750px) {

}
</style>
