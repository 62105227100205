<template>
  <div class="page-header">プライバシポリシー</div>
  <Breadcrumb menu="プライバシポリシー" />  
  <div class="content">
    <p>
      株式会社アスラク（以下「当社」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、
      全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
    </p>
    <p class="title">1、個人情報の管理</p>
    <p>
      当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、
      セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
    </p>
    <p class="title">2、個人情報の利用目的</p>
    <p>
      お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、メールや顧客アフターサービスに利用いたします。
    </p>
    <p class="title">3、個人情報の第三者への開示・提供の禁止</p>
    <p>
      当社は、あらかじめ同意を得た場合、法令に基づき提供を要求された場合、ご本人、または公共の利益（生命・健康・財産など）を保護するために必要である場合を除き、個人情報を第三者へ提供しません。
    </p>
    <p class="title">4、個人情報の安全対策</p>
    <p>
      当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
    </p>
    <p class="title">5、ご本人の照会</p>
    <p>
      お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
    </p>
    <p class="title">6、法令、規範の遵守と見直し</p>
    <p>
      当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
    </p>
  </div>
</template>

<script>
import Breadcrumb from '../components/atoms/Breadcrumb.vue'

export default {
  name: 'News',
  components: {
    Breadcrumb,
  },
  data() {
  },
}
</script>

<style lang="scss">
.page-header {
  background: #F7F7F7;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-top: #cee7f0 1px solid;
  color: black;
  justify-content: center;
  height: 100px;
}

.content {
  margin: auto;
  padding-top: 20px;
  min-height: 46vh;
  max-width: 900px;
  padding-bottom: 100px;
  .title {
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(212, 199, 199);
  }
}

@media (min-width: 751px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .content {
    width: 90%;
  }
}
</style>